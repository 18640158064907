//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { resendVerifyEmail } from '@motionelements/core/src/services/account.service.js';

export default {
  name: 'UnverifiedModal',
  data() {
    return {
      faXmark,
    };
  },
  computed: {
    ...mapState({
      memberEmail: state => state.user.member.email,
    }),
  },
  methods: {
    resendVerify() {
      this.loading = true;
      resendVerifyEmail()
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
